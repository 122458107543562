import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <div id="recaptcha-container" style={{ display: 'none' }} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const script = document.createElement('script');
script.src = 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit';
script.async = true;
script.defer = true;
document.body.appendChild(script);
