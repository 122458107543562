import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ar from './locales/ar.json';
import cs from './locales/cs.json';
import da from './locales/da.json';
import de from './locales/de.json';
import el from './locales/el.json';
import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import hi from './locales/hi.json';
import it from './locales/it.json';
import jp from './locales/jp.json';
import kr from './locales/kr.json';
import nl from './locales/nl.json';
import pt from './locales/pt.json';
import rm from './locales/rm.json';
import ru from './locales/ru.json';
import th from './locales/th.json';
import vi from './locales/vi.json';


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      ar: {translation: ar},
      cs: { translation: cs },
      da: { translation: da },
      el: { translation: el },
      hi: { translation: hi },
      it: { translation: it },
      jp: { translation: jp },
      kr: { translation: kr },
      nl: { translation: nl },
      pt: { translation: pt },
      rm: { translation: rm },
      th: { translation: th },
      ru: { translation: ru },
      vi: { translation: vi },
      de: { translation: de},
      es: { translation : es},
      fr: { translation : fr}
    },
    fallbackLng: 'en', // Ngôn ngữ mặc định nếu không tìm thấy ngôn ngữ phù hợp
    interpolation: {
      escapeValue: false, // Không escape giá trị
    },
  });

export default i18n;